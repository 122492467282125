.AnyQust-module__mobileCallUs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.AnyQust-module__mobileCallUs > a,
.AnyQust-module__mobileCallUs > button {
  width: calc(50% - 15px / 2);
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  border-radius: 4px;
  box-sizing: border-box;
}

.AnyQust-module__actAsHref {
  background-color: transparent;
  border: none;
  outline: none;
}

.AnyQust-module__mobileCallUs > a > img,
.AnyQust-module__mobileCallUs > button > img {
  margin: 0 5px;
}

.AnyQust-module__socialLinks {
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 56px;
  border: 1px solid #f4f9fe;
  background: #e5eef4;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.AnyQust-module__socialLinks > h2 {
  font-weight: bold;
  color: #154f9c;
  font-size: 24px;
  line-height: 32px; /* 133.333% */
}

.AnyQust-module__socialLinks > div {
  margin-top: 16px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.AnyQust-module__socialLinks > div svg,
.AnyQust-module__socialLinks > div svg * {
  fill: #6c7a8d;
}

.AnyQust-module__anyQustContainer {
  position: relative;
}

.AnyQust-module__callHelpContainer {
  max-width: 955px;
  background: #154f9c;
  background: linear-gradient(0deg, #154f9c 0, #2f87cb 100%);
  padding: 60px 0;
  margin: 0 auto;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AnyQust-module__callHelpContainer > span,
.AnyQust-module__callHelpContainer > span > a {
  display: block;
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  direction: ltr;
}

.AnyQust-module__callHelpContainer > strong {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;
}

.AnyQust-module__callHelpContainer>strong::after {
  content: "";
  background-color: #ec6234;
  height: 3px;
  width: 64px;
  position: absolute;
  bottom: calc(-3px / 2);
  left: calc(50% - 64px / 2);
}

.AnyQust-module__callHelpContainer > p {
  color: #fff;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}

.AnyQust-module__callHelpContainer > span,
.AnyQust-module__callHelpContainer > span > a {
  display: block;
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  direction: ltr;
}

.AnyQust-module__callHelpContainer > a {
  background-color: #fff;
  color: var(--blueColor);
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.AnyQust-module__callHelpContainer>a>img, .AnyQust-module__callHelpContainer>a>span {
  margin: 0 5px;
}

@media screen and (min-width: 1025px) {
  .AnyQust-module__socialLinks {
    border-radius: 16px;
    min-height: 168px;
    max-width: 955px;
    margin-top: 16px;
  }
  .AnyQust-module__anyQustContainer::before {
    content: "";
    position: absolute;
    display: block;
    height: 50%;
    width: 100%;
    left: 0;
    background-color: #f4f9fe;
    z-index: -1;
  }
}

@media screen and (max-width: 1024px) {
  .AnyQust-module__socialLinks {
    margin-top: 40px;
  }
  .AnyQust-module__contMaxWidth {
    max-width: 100%;
    width: 100%;
  }
}
